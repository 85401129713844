import React from "react";
import ProjectCard from "./ProjectCard";
import Projects from "./Projects";
import "./ProjectsSection.css"

const ProjectsSection = () => {
    return (
        <div className="portfolio">
            <h2>My Projects</h2>
            <div className="projects-container">
                {Projects.map((project) => (
                    <ProjectCard
                        key={project.id}
                        title={project.title}
                        description={project.description}
                        image={project.image}
                        link={project.link}
                        githubLink={project.githubLink}
                    />
                ))}
            </div>
        </div>
    )
}

export default ProjectsSection