import React from "react"
import Home from "./components/Home"
import Footer from "./components/Footer"

function App() {
  return(
    <div className="app">
        <Home />
        <Footer />
    </div>
  )
}

export default App