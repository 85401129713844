import React from 'react';
import { Link } from "react-scroll";
import { easeOutQuad } from "easing-functions";
import { FaLinkedin, FaGithub } from 'react-icons/fa';  // Import LinkedIn and GitHub icons
import './Navbar.css';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <h2>Marius Kieldsen</h2>
      </div>
      <ul className="navbar-menu">
        <li>
          <a 
            href="https://www.linkedin.com/in/mjkieldsen/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="social-icon"
          >
            <FaLinkedin size={24} />
          </a>
        </li>
        <li>
          <a 
            href="https://github.com/prindeon" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="social-icon"
          >
            <FaGithub size={24} />
          </a>
        </li>
        <li>
          <Link
            to="projects-section"
            smooth={true}
            duration={1500}
            easing={easeOutQuad}
          >
            Projects
          </Link>
        </li>
        <li>
          <a 
            href="https://www.linkedin.com/in/mjkieldsen/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="contact-button"
          >
            Contact Me
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;