import React from "react";
import "./Footer.css"

const Footer = () => {
    return (
        <footer className="footer">
            <div className="flexbox">
                <h2>Let's work together</h2>
                <a href="mailto:mjkieldsen@gmail.com">mjkieldsen@gmail.com</a>
            </div>
        </footer>
    )
}

export default Footer