// src/components/Skills.jsx

import React from 'react';
import './Skills.css'; // Import corresponding CSS
import { FaReact, FaCss3Alt, FaBrush } from 'react-icons/fa';
import { IoSchool } from "react-icons/io5";

const Skills = () => {
  return (
    <section className="skills-section">
      {/* Visual Element */}
      <div className="skills-visual">
        <div className="circle"></div>
        <div className="triangle"></div>
      </div>

      {/* Skills Content */}
      <div className="skills-content">
        <h2>My Skills</h2>
        <div className="skill-blocks">
          {/* Skill 1 */}
          <div className="skill">
              <FaCss3Alt className="skill-icon" />
              <div className="skill-info">
                  <h3>HTML/CSS/JS</h3>
                  <p>
                    I have 4 years of coding experience with front-end web development, be it personal or school projects.
                  </p>
              </div>
          </div>

          {/* Skill 2 */}
          <div className="skill">
            <FaBrush className="skill-icon" />
            <div className="skill-info">
              <h3>Clean code</h3>
              <p>
              I love creating responsive websites and apps, where the code is as pretty as the website. I like to put heavy focus on writing clean, high-performing code that is semantically correct and optimized for search engines.
              </p>
            </div>
          </div>

          {/* Skill 3 */}
          <div className="skill">
            <FaReact className="skill-icon" />
            <div className="skill-info">
              <h3>React</h3>
              <p>
              I recently learned react, and I’m always improving and love using it’s capabilities to create bigger projects with huge site maps and lots of components.
              </p>
            </div>
          </div>

          {/* Skill 4 */}
          <div className="skill">
            <IoSchool className="skill-icon" />
            <div className="skill-info">
              <h3>Always learning</h3>
              <p>
                I do my best to always learn and improve my knowledge and coding methods to bring the most value to every project.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;