import React from "react";
import "./ProjectCard.css"
import { FaGithub } from "react-icons/fa"


const ProjectCard = ({ title, description, image, link, githubLink }) => {
    return (
        <div className="project-card">
            <img src={image} alt={`${title} screenshot`} className="project-image" />
            <div className="project-content">
                <h3>{title}</h3>
                <p>{description}</p>
                <div className="project-links">
                    {link && (
                        <a 
                            href={link} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="project-link solution-link"
                        >
                            Visit Website
                        </a>
                    )}
                    {githubLink && (
                        <a 
                            href={githubLink} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="project-link github-link"
                            title="View Source Code on GitHub"
                        >
                            <FaGithub className="github-icon" /> View on GitHub
                        </a>
                    )} 
                </div>
            </div>
        </div>
    )
}

export default ProjectCard